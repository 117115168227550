import React, { Fragment, Component } from 'react';
import { NavLink as ActiveLink, Link } from 'react-router-dom';

// import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  // DropdownItem,
  Button,
  UncontrolledPopover,
  PopoverBody,
  // Card,
  // CardBody,
  // FormGroup,
  // Label,
  Input,
  Form,
  InputGroupAddon,
  InputGroup
   } from 'reactstrap';


const mobBG = {
  backgroundColor: '#e3e3e3'
}   

const mobmenu = {
  color: '#971a28',
  fontSize: '20px',
  fontFamily: 'Fira Sans, sans-serif'
}


const OurBusinessMenu = [
  {
    "menutitle": "Enterprise",
    "menuitem": [
      {
        "logo": "",
        "header": "PLDT Enterprise",
        "content": "<strong>PLDT Enterprise</strong> is the group’s B2B arm and is tasked with the mission of empowering Philippine enterprises large and small as they pursue their respective digital transformation journeys.",
        "link": "https://www.pldtenterprise.com/"
      },
      {
        "logo": "",
        "header": "ePLDT",
        "content": "<strong>ePLDT</strong> is the premier information and communications technology company in the country, delivering world-class solutions to both large enterprises and the SMEs across various industries.",
        "link": "https://www.epldt.com/"
      }
    ]
  },
  {
    "menutitle": "Wireless",
    "menuitem": [
      {
        "logo": "",
        "header": "Smart ",
        "content": "<strong>Smart Communications</strong> is the group’s wireless services provider that provides a wide range of innovative prepaid, postpaid and mobile broadband services.",
        "link": "https://smart.com.ph/corporate"
      },
      {
        "logo": "",
        "header": "TNT",
        "content": "<strong>TNT</strong> is Smart's value brand that provides fast and reliable LTE connections and affordable call, text and data services.",
        "link": "https://tntph.com/"
      },
      {
        "logo": "",
        "header": "Sun",
        "content": "<strong>Sun</strong>   offers a wide range of service innovations for mobile telephony from voice, messaging and international roaming services to wireless broadband and value-added services.",
        "link": "https://suncellular.com.ph/"
      }
    ]
  }
]

function RenderBusinessDropdown ({menudropdown}){
  return (
    menudropdown.map(menu => {
      return (
        <UncontrolledDropdown direction="right" key={menu.menutitle}>
          <DropdownToggle tag="li">
            <NavLink>
              {menu.menutitle}
              <i className="fas fa-chevron-right position-absolute"></i>
            </NavLink>
            
          </DropdownToggle>
          <DropdownMenu>
          {menu.menuitem.map(item => {
            return(
             <Fragment key={item.header}>
              <NavItem>
                <NavLink id={`OurBusiness-${item.header}`.replace(/\s+/g, "")}>
                  {item.header}
                  <i className="fas fa-chevron-right position-absolute"></i>
                </NavLink>
              </NavItem>
                <UncontrolledPopover trigger="legacy" placement="right" className="ourbusiness-popover" target={`OurBusiness-${item.header}`.replace(/\s+/g, "")}>
                  <PopoverBody>
                    <span dangerouslySetInnerHTML={{__html: item.content}} />
                    <br/><br/>
                     <Button href={item.link} target="_blank" rel="noopener noreferrer">GO TO WEBSITE</Button>
                    </PopoverBody>
                </UncontrolledPopover>
              </Fragment>
            )
          })}
          </DropdownMenu>
      </UncontrolledDropdown>

      )
    })
  )
}

const mobilemenu = [
  {
    "main": "Home",
    "submenu": "/"
  },
  {
    "main": "About PLDT",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/about-us"
      },
      {
        "menu": "What Drives Us",
        "subnav": "/about-us/what-drives-us"
      },
      {
        "menu": "PLDT's Business",
        "subnav": "/about-us/pldts-business"
      },
      {
        "menu": "Company Leadership",
        "subnav": "/about-us/company-leadership"
      },
      {
        "menu": "Company Timeline",
        "subnav": "/about-us/company-timeline"
      },
      {
        "menu": "Corporate Citizenship",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/about-us/corporate-citizenship"
          },
          {
            "nav": "Stakeholder Management",
            "link": "/about-us/corporate-citizenship/stakeholder-management"
          },
          {
            "nav": "Shared Value Programs",
            "link": "/about-us/corporate-citizenship/shared-value-programs"
          }
        ]
      },
      {
        "menu": "Awards and Citations",
        "subnav": "/about-us/awards-and-citations"
      }
    ]
  },
  {
    "main": "Newsroom",
    "submenu": "/newsroom"
  },
  {
    "main": "Governance",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/corporate-governance-in-pldt"
      },
      {
        "menu": "Message from the Chairman",
        "subnav": "/corporate-governance-in-pldt/message-from-the-chairman"
      },
      {
        "menu": "Message from the President",
        "subnav": "/corporate-governance-in-pldt/message-from-the-president-and-ceo"
      },
      {
        "menu": "Governance Structure",
        "subnav": [
          {
            "nav": "Our Board of Directors",
            "link": "/corporate-governance-in-pldt/governance-structures"
          },
          {
            "nav": "About the Board",
            "link": "/corporate-governance-in-pldt/governance-structures/about-the-board"
          },
          {
            "nav": "Board Committees",
            "link": "/corporate-governance-in-pldt/governance-structures/board-committees"
          },
          {
            "nav": "Executive Implementation",
            "link": "/corporate-governance-in-pldt/governance-structures/executive-implementation"
          },
          {
            "nav": "CG Framework",
            "link": "/corporate-governance-in-pldt/governance-structures/cg-framework"
          }
        ]
      },
      {
        "menu": "Policies, Processes and Practices",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices"
          },
          {
            "nav": "Education and Communication",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/education-and-communication"
          },
          {
            "nav": "Monitoring and Evaluation",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/monitoring-and-evaluation"
          },
          {
            "nav": "Disclosures",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/disclosures"
          },
          {
            "nav": "Networking",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/networking"
          }
        ]
      },
      {
        "menu": "Sitemap",
        "subnav": "/corporate-governance-in-pldt/site-map"
      },
      {
        "menu": "Our Stakeholders",
        "subnav": "/corporate-governance-in-pldt/our-stakeholders"
      },
    ]
  },
  {
    "main": "Investor Relations",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/investor-relations"
      },
      {
        "menu": "Annual and Sustainability Reports",
        "subnav": "/investor-relations/annual-and-sustainability-reports"
      },
      {
        "menu": "Annual Stockholders Meeting",
        "subnav": "/investor-relations/shareholder-information/latest-shareholders-news"
      },
      {
        "menu": "Press Releases and Disclosures",
        "subnav": [
          {
            "nav": "Acquisitions, Partnerships and Others",
            "link": "/investor-relations/press-releases-and-disclosures/acquisitions-partnerships-and-others"
          },
          {
            "nav": "Board Meeting / Stockholders' Related Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures"
          },
          {
            "nav": "Capex Related Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures/capex-related-disclosures"
          },
          {
            "nav": "Financial Results",
            "link": "/investor-relations/press-releases-and-disclosures/financial-results"
          },
          {
            "nav": "News Clarifications Filed with PSE",
            "link": "/investor-relations/press-releases-and-disclosures/news-clarifications-filed-with-pse"
          },
          {
            "nav": "Organizational Announcements",
            "link": "/investor-relations/press-releases-and-disclosures/organizational-announcements"
          },
          {
            "nav": "Share Buy-Back Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures/share-buy-back-disclosures"
          },
          {
            "nav": "Sustainability",
            "link": "/investor-relations/press-releases-and-disclosures/sustainability"
          },
          {
            "nav": "Maya Innovations Holdings Pte. Ltd. (VIH)",
            "link": "/investor-relations/press-releases-and-disclosures/voyager-paymaya"
          }
        ]
      },
      {
        "menu": "Financial Results",
        "subnav": "/investor-relations/financial-results"
      },
      {
        "menu": "Presentations",
        "subnav": "/investor-relations/presentations"
      },
      {
        "menu": "Podcasts",
        "subnav": "/investor-relations/podcasts"
      },
      {
        "menu": "Shareholder Information",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/investor-relations/shareholder-information"
          },
          {
            "nav": "Beneficial Ownership",
            "link": "/investor-relations/shareholder-information/beneficial-ownership"
          },
          {
            "nav": "Dividend Info",
            "link": "/investor-relations/shareholder-information/dividend-info"
          },
          {
            "nav": "GIS Archive",
            "link": "/investor-relations/shareholder-information/latest-shareholders-news/gis-archive"
          },
          {
            "nav": "Public Ownership Report",
            "link": "/investor-relations/shareholder-information/public-ownership-report"
          },
          {
            "nav": "Latest Shareholders' News",
            "link": "/investor-relations/shareholder-information/latest-shareholders-news"
          },
          {
            "nav": "Shareholding Structure",
            "link": "/investor-relations/shareholder-information/shareholding-structure"
          },
          {
            "nav": "Top 100 Stockholders",
            "link": "/investor-relations/shareholder-information/top-100-stockholders"
          },
          {
            "nav": "Transfer Agents",
            "link": "/investor-relations/shareholder-information/transfer-agents"
          }
        ]
      },
      {
        "menu": "Credit Ratings",
        "subnav": "/investor-relations/credit-ratings-reports"
      },
      {
        "menu": "PLDT U.S. Dollar Bond",
        "subnav": "/investor-relations/pldt-u-s-dollar-bond"
      },
      {
        "menu": "PLDT Retail Bonds",
        "subnav": "/investor-relations/pldt-retail-bond"
      },
      {
        "menu": "Calendar of Events",
        "subnav": "/investor-relations/calendar-of-events"
      },
      {
        "menu": "Analysts and Investors Contact",
        "subnav": "/investor-relations/analysts-and-investors-contact"
      },
    ]
  },
  {
    "main": "Careers",
    "submenu": "https://careers.pldt.com.ph/",
    "type": "ext"
  },
  {
    "main": "Privacy Policy",
    "submenu": "/privacy-policy"
  },
  {
    "main": "Terms of Use",
    "submenu": "/terms-of-use"
  },
  {
    "main": "Our Business",
    "submenu": [
      {
        "menu": "Home",
        "subnav": "https://pldthome.com/",
        "type": "ext"
      },
      {
        "menu": "Enterprise",
        "subnav": [
          {
            "nav": "PLDT Enterprise",
            "link": "https://www.pldtenterprise.com/",
            "type": "ext"
          },
          {
            "nav": "ePLDT",
            "link": "https://www.epldt.com/",
            "type": "ext"
          }
        ]
      },
      {
        "menu": "Wireless",
        "subnav": [
          {
            "nav": "Smart",
            "link": "https://smart.com.ph/corporate",
            "type": "ext"
          },
          {
            "nav": "TNT",
            "link": "https://tntph.com/",
            "type": "ext"
          },
          {
            "nav": "Sun",
            "link": "https://suncellular.com.ph/",
            "type": "ext"
          }
        ]
      },
      {
        "menu": "International",
        "subnav": "https://www.pldtglobal.com/",
        "type": "ext"
      },
      {
        "menu": "Digital",
        "subnav": "https://www.voyagerinnovation.com/",
        "type": "ext"
      },
    ]
  },
  {
    "main": "Contact Us",
    "submenu": "/contact-us"
  }
]

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      burgerIcon: 'fa-solid fa-bars',
      submenuCollapse: '',
      subnavCollapse: ''
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleSubmenu = this.toggleSubmenu.bind(this);
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount(){
    window.addEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
        return;
    }
    this.closeToggle();
  }
    
  toggleNavbar() {
    this.setState({
      opened: !this.state.opened,
      burgerIcon: 'fa-solid fa-x'
    }); 

    if (this.state.opened === false){
      this.setState({
        burgerIcon: 'fa-solid fa-x'
      }); 
    }
    else {
      this.setState({
        burgerIcon: 'fa-solid fa-bars',
      }); 
    }

    if (this.state.submenuCollapse === ''){
      this.setState({
        submenuCollapse: '',
      });
    }

    if (this.state.subnavCollapse === ''){
      this.setState({
        subnavCollapse: '',
      });
    }
  }

  toggleSubmenu = nav => () => {
    this.setState({ 
      submenuCollapse: this.state.submenuCollapse === nav ? "" : nav,
      subnavCollapse: ''
    }); 
  }

  toggleSubnav = nav => () => {
    this.setState({ 
      subnavCollapse: this.state.subnavCollapse === nav ? "" : nav,
    }); 
  }

  closeToggle(){
    this.setState({
      opened: false,
      burgerIcon: 'fa-solid fa-bars',
      submenuCollapse: '',
      subnavCollapse: '',
    });
  }

  closeMenuDropdown(){
    if (this.state.submenuCollapse !== ''){
      this.setState({
        opened: false,
        burgerIcon: 'fa-solid fa-bars',
        subnavCollapse: '',
      });
    }
  }

  closeSubnavDropdown(){
    if (this.state.subnavCollapse !== ''){
      this.setState({
        opened: false,
        burgerIcon: 'fa-solid fa-bars',
      });
    }
  }

  setActiveClass(page){
  
    let str = window.location.pathname.slice(0, 10);

    switch (str){
      case '/about-us':
      case '/about-us/':
        if (page === 'About PLDT'){
          return  'mobmenu-active';
        };
        break;
      case '/corporate':
        if (page === 'Governance'){
          return  'mobmenu-active';
        };
        break;
      case '/investor-':
        if (page === 'Investor Relations'){
          return  'mobmenu-active';
        };
        break;
      default:
        return '';
    }
  }

  render(){
    return (
      <section ref={node => this.node = node}>
        <Container fluid className="business-navbar px-0" id="mobile-navbar" >
          <Navbar className="px-0">
            <NavbarBrand href="/" className="pl-3"><img src="/images/pldt-corpwebsite-navigation-logo.png" alt="pldt-logo" className="d-block img-fluid"/></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mt-4 pr-4"><i className={`${this.state.burgerIcon} text-white`} /></NavbarToggler>
            <Collapse isOpen={this.state.opened} navbar style={mobBG} className="px-3 py-2">

              <Form className="homepageSearchBar" id="search-mobilenav" action="/search">
                <InputGroup >
                    <Input
                      type="search"
                      name="search"
                      placeholder="What are you looking for?"
                  />
                  <InputGroupAddon addonType="append"><i className="fas fa-search"></i></InputGroupAddon>
                </InputGroup>
              </Form>
              
              {
                mobilemenu.map(menu =>{
                  return (
                    typeof(menu.submenu) === 'string'
                    ?
                    menu.type === 'ext'
                    ?
                    <NavLink key={menu.main} href={menu.submenu} target="_blank" rel="noopener noreferrer" style={mobmenu} className="font-weight-bold">{menu.main}</NavLink>
                    :
                    <NavLink key={menu.main} tag={ActiveLink} exact style={mobmenu} className="font-weight-bold" to={menu.submenu} onClick={(e) => this.closeToggle()}>{menu.main}</NavLink>
                    :
                    <Fragment key={menu.main} >                    
                      <NavLink style={mobmenu} className={`${this.setActiveClass(menu.main)} font-weight-bold position-relative`} onClick={this.toggleSubmenu(menu.main)}>
                        {menu.main}
                        {
                          this.state.submenuCollapse === menu.main
                          ? <span className="collapseIcon">-</span>
                          : <span className="collapseIcon">+</span>
                        }
                      </NavLink>
                      <Collapse isOpen={this.state.submenuCollapse === menu.main} navbar>
                        <Nav vertical className="px-3">
                          {
                            menu.submenu.map(submenu => {
                              return (
                                typeof(submenu.subnav) === 'string'
                                ?
                                  submenu.type === 'ext'
                                  ?
                                  <NavLink key={submenu.menu} className="drop-item" href={submenu.subnav} target="_blank" rel="noopener noreferrer">{submenu.menu}</NavLink>
                                  :
                                  <NavLink tag={ActiveLink} exact key={submenu.menu} className="drop-item" to={submenu.subnav} onClick={(e) => this.closeMenuDropdown()}>{submenu.menu}</NavLink>
                                :
                                <Fragment key={submenu.menu}>
                                  <NavLink style={mobmenu} className="drop-item-sub position-relative" onClick={this.toggleSubnav(submenu.menu)}>
                                    {submenu.menu}
                                    {
                                      this.state.subnavCollapse === submenu.menu
                                      ? <span className="collapseIcon">-</span>
                                      : <span className="collapseIcon">+</span>
                                    }
                                  </NavLink>
                                  <Collapse isOpen={this.state.subnavCollapse === submenu.menu} navbar>
                                    <Nav vertical className="px-3">
                                      {
                                        submenu.subnav.map(subnav => {
                                          return (
                                            subnav.type === 'ext'
                                            ?
                                            <NavLink key={subnav.nav} className="drop-item" href={subnav.link} target="_blank" rel="noopener noreferrer">{subnav.nav}</NavLink>
                                            :
                                            <NavLink tag={ActiveLink} exact key={subnav.nav} className="drop-item" to={subnav.link} onClick={(e) => this.closeSubnavDropdown()}>{subnav.nav}</NavLink>
                                          )
                                        })
                                      }
                                    </Nav>
                                  </Collapse>
                                </Fragment>
                              )}
                            )
                          }
                        </Nav>  
                      </Collapse>
                    </Fragment>
                  )
                })
              }
            </Collapse>
          </Navbar>
        </Container>
      </section>
    )
  }
}

const desktopmenu = [
  {
    "main": "About PLDT",
    "pathname": "/about-us",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/about-us"
      },
      {
        "menu": "What Drives Us",
        "subnav": "/about-us/what-drives-us"
      },
      {
        "menu": "PLDT's Business",
        "subnav": "/about-us/pldts-business"
      },
      {
        "menu": "Company Leadership",
        "subnav": "/about-us/company-leadership"
      },
      {
        "menu": "Company Timeline",
        "subnav": "/about-us/company-timeline"
      },
      {
        "menu": "Corporate Citizenship",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/about-us/corporate-citizenship"
          },
          {
            "nav": "Stakeholder Management",
            "link": "/about-us/corporate-citizenship/stakeholder-management"
          },
          {
            "nav": "Shared Value Programs",
            "link": "/about-us/corporate-citizenship/shared-value-programs"
          }
        ]
      },
      {
        "menu": "Awards and Citations",
        "subnav": "/about-us/awards-and-citations"
      }
    ]
  },
  {
    "main": "Newsroom",
    "submenu": "/newsroom"
  },
  {
    "main": "Governance",
    "pathname": "/corporate-governance-in-pldt",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/corporate-governance-in-pldt"
      },
      {
        "menu": "Message from the Chairman",
        "subnav": "/corporate-governance-in-pldt/message-from-the-chairman"
      },
      {
        "menu": "Message from the President",
        "subnav": "/corporate-governance-in-pldt/message-from-the-president-and-ceo"
      },
      {
        "menu": "Governance Structure",
        "subnav": [
          {
            "nav": "Our Board of Directors",
            "link": "/corporate-governance-in-pldt/governance-structures"
          },
          {
            "nav": "About the Board",
            "link": "/corporate-governance-in-pldt/governance-structures/about-the-board"
          },
          {
            "nav": "Board Committees",
            "link": "/corporate-governance-in-pldt/governance-structures/board-committees"
          },
          {
            "nav": "Executive Implementation",
            "link": "/corporate-governance-in-pldt/governance-structures/executive-implementation"
          },
          {
            "nav": "CG Framework",
            "link": "/corporate-governance-in-pldt/governance-structures/cg-framework"
          }
        ]
      },
      {
        "menu": "Policies, Processes and Practices",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices"
          },
          {
            "nav": "Education and Communication",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/education-and-communication"
          },
          {
            "nav": "Monitoring and Evaluation",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/monitoring-and-evaluation"
          },
          {
            "nav": "Disclosures",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/disclosures"
          },
          {
            "nav": "Networking",
            "link": "/corporate-governance-in-pldt/policies-processes-and-practices/networking"
          }
        ]
      },
      {
        "menu": "Sitemap",
        "subnav": "/corporate-governance-in-pldt/site-map"
      },
      {
        "menu": "Our Stakeholders",
        "subnav": "/corporate-governance-in-pldt/our-stakeholders"
      },
    ]
  },
  {
    "main": "Investor Relations",
    "pathname": "/investor-relations",
    "submenu": [
      {
        "menu": "Overview",
        "subnav": "/investor-relations"
      },
      {
        "menu": "Annual and Sustainability Reports",
        "subnav": "/investor-relations/annual-and-sustainability-reports"
      },
      {
        "menu": "Annual Stockholders Meeting",
        "subnav": "/investor-relations/shareholder-information/latest-shareholders-news"
      },
      {
        "menu": "Press Releases and Disclosures",
        "subnav": [
          {
            "nav": "Acquisitions, Partnerships and Others",
            "link": "/investor-relations/press-releases-and-disclosures/acquisitions-partnerships-and-others"
          },
          {
            "nav": "Board Meeting / Stockholders' Related Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures"
          },
          {
            "nav": "Capex Related Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures/capex-related-disclosures"
          },
          {
            "nav": "Financial Results",
            "link": "/investor-relations/press-releases-and-disclosures/financial-results"
          },
          {
            "nav": "News Clarifications Filed with PSE",
            "link": "/investor-relations/press-releases-and-disclosures/news-clarifications-filed-with-pse"
          },
          {
            "nav": "Organizational Announcements",
            "link": "/investor-relations/press-releases-and-disclosures/organizational-announcements"
          },
          {
            "nav": "Share Buy-Back Disclosures",
            "link": "/investor-relations/press-releases-and-disclosures/share-buy-back-disclosures"
          },
          {
            "nav": "Sustainability",
            "link": "/investor-relations/press-releases-and-disclosures/sustainability"
          },
          {
            "nav": "Maya Innovations Holdings Pte. Ltd. (VIH)",
            "link": "/investor-relations/press-releases-and-disclosures/voyager-paymaya"
          }
        ]
      },
      {
        "menu": "Financial Results",
        "subnav": "/investor-relations/financial-results"
      },
      {
        "menu": "Presentations",
        "subnav": "/investor-relations/presentations"
      },
      {
        "menu": "Podcasts",
        "subnav": "/investor-relations/podcasts"
      },
      {
        "menu": "Shareholder Information",
        "subnav": [
          {
            "nav": "Overview",
            "link": "/investor-relations/shareholder-information"
          },
          {
            "nav": "Beneficial Ownership",
            "link": "/investor-relations/shareholder-information/beneficial-ownership"
          },
          {
            "nav": "Dividend Info",
            "link": "/investor-relations/shareholder-information/dividend-info"
          },
          {
            "nav": "GIS Archive",
            "link": "/investor-relations/shareholder-information/latest-shareholders-news/gis-archive"
          },
          {
            "nav": "Public Ownership Report",
            "link": "/investor-relations/shareholder-information/public-ownership-report"
          },
          {
            "nav": "Latest Shareholders' News",
            "link": "/investor-relations/shareholder-information/latest-shareholders-news"
          },
          {
            "nav": "Shareholding Structure",
            "link": "/investor-relations/shareholder-information/shareholding-structure"
          },
          {
            "nav": "Top 100 Stockholders",
            "link": "/investor-relations/shareholder-information/top-100-stockholders"
          },
          {
            "nav": "Transfer Agents",
            "link": "/investor-relations/shareholder-information/transfer-agents"
          }
        ]
      },
      {
        "menu": "Credit Ratings",
        "subnav": "/investor-relations/credit-ratings-reports"
      },
      {
        "menu": "PLDT U.S. Dollar Bond",
        "subnav": "/investor-relations/pldt-u-s-dollar-bond"
      },
      {
        "menu": "PLDT Retail Bonds",
        "subnav": "/investor-relations/pldt-retail-bond"
      },
      {
        "menu": "Calendar of Events",
        "subnav": "/investor-relations/calendar-of-events"
      },
      {
        "menu": "Analysts and Investors Contact",
        "subnav": "/investor-relations/analysts-and-investors-contact"
      },
    ]
  },
  {
    "main": "Careers",
    "submenu": "https://careers.pldt.com.ph/",
    "type": "ext"
  },
]

class DesktopNav extends Component {
  constructor(props) {
    super(props);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.dropdownSubnav = this.dropdownSubnav.bind(this);
    this.state = {
      collapse: '',
      dropdownOpen: '',
      dropdownSubnavOpen: '',
    };
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount(){
    window.addEventListener('mousedown', this.handleClickOutside, false);
  }


  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
        return;
    }
    this.clickOutside();
  }

  clickOutside(){
    if (this.state.dropdownOpen === ''){
      this.setState({
        dropdownOpen: '',
      });
    }

    if (this.state.dropdownSubnavOpen === ''){
      this.setState({
        dropdownSubnavOpen: '',
      });
    }
  }

  dropdownToggle =  (menu, pathname) => () => {
    if (this.state.dropdownOpen === ''){
      return(
        this.setState({ 
          dropdownOpen: menu,  
        })) 
        
    }
    else {
      this.setState({ 
        dropdownOpen: '', 
      });
    }
  }

  dropdownSubnav = menu => () => {
    this.setState({ 
      dropdownSubnavOpen: this.state.dropdownSubnavOpen === menu ? "" : menu,
    }); 
  }

  closeDropdown(){
     this.setState({
      dropdownOpen: '',
      dropdownSubnavOpen: '',
    });
  }

  closeMenuDropdown(){
    if (this.state.dropdownOpen !== ''){
      this.setState({
        dropdownOpen: '',
        dropdownSubnavOpen: '',
      });
    }
  }

  closeSubnavDropdown(){
    this.setState({
      dropdownOpen: '',
    });
  }

  setActiveClass(page){
  
    let str = window.location.pathname.slice(0, 10);

    switch (str){
      case '/about-us':
      case '/about-us/':
        if (page === 'About PLDT'){
          return  'menu-active';
        };
        break;
      case '/corporate':
        if (page === 'Governance'){
          return  'menu-active';
        };
        break;
      case '/investor-':
        if (page === 'Investor Relations'){
          return  'menu-active';
        };
        break;
      default:
        return '';
    }
  }

  render(){
    return (
      <section className="site-navbar" ref={node => this.node = node}>
        <Container>
          <Navbar>
            <NavbarBrand tag={Link} to="/" className="position-absolute"><img src="/images/pldt-corpwebsite-navigation-logo.png" alt="pldt-logo" className="mx-auto d-block img-fluid"/></NavbarBrand>
            <Nav className="ml-auto" >
              {
                desktopmenu.map(menu =>{
                  return (
                    typeof(menu.submenu) === 'string'
                    ?
                      menu.type === 'ext'
                      ?
                      <NavLink key={menu.main}  href={menu.submenu} target="_blank" rel="noopener noreferrer" className="parent-nav">{menu.main}</NavLink>
                      :
                      <NavLink tag={ActiveLink} exact key={menu.main} to={menu.submenu} onClick={(e) => this.closeDropdown()} className="parent-nav">{menu.main}</NavLink>
                    :
                    <Dropdown nav inNavbar isOpen={this.state.dropdownOpen === menu.main} toggle={this.dropdownToggle(menu.main, menu.pathname)} key={menu.main} className={`${this.setActiveClass(menu.main)} parent-nav`}> 
                    <DropdownToggle>{menu.main}</DropdownToggle>
                      <DropdownMenu className="desktop-nav" style={mobBG}>
                        {
                          menu.submenu.map(submenu => {
                            return (
                              typeof(submenu.subnav) === 'string'
                              ?
                              <NavLink tag={ActiveLink} exact key={submenu.menu} to={submenu.subnav} onClick={(e) => this.closeMenuDropdown()}>{submenu.menu}</NavLink>
                              :
                              <Fragment key={submenu.menu}>
                                <NavLink className="drop-item-sub position-relative" onClick={this.dropdownSubnav(submenu.menu)}>
                                  {submenu.menu}
                                  {
                                    this.state.dropdownSubnavOpen === submenu.menu
                                    ? <span className="collapseIcon">-</span>
                                    : <span className="collapseIcon">+</span>
                                  }
                                </NavLink>
                                <Collapse isOpen={this.state.dropdownSubnavOpen === submenu.menu} navbar>
                                  <Nav vertical className="desktop-nav px-3" style={mobBG}>
                                    {
                                      submenu.subnav.map(subnav => {
                                        return (
                                        <NavLink tag={ActiveLink} exact key={subnav.nav} to={subnav.link} onClick={(e) => this.closeSubnavDropdown()}>{subnav.nav}</NavLink>
                                        )
                                      })
                                    }
                                  </Nav>
                                </Collapse>
                              </Fragment>
                            )
                          })
                        }
                      </DropdownMenu>
                    </Dropdown>
                  )
                })
              }
            </Nav>
          </Navbar>
        </Container>
      </section> 
    )
  }
}


class DekstopGlobalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSearchOpen: false,
      searchIcon: 'fas fa-search',
      searchClass: 'hideSearchBar',
      quickSearchValue: '',
    };
    this.toggle = this.toggle.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.handleQuickSearchChange = this.handleQuickSearchChange.bind(this);
  }

  //  componentDidMount(){
  //   window.addEventListener('mousedown', this.handleClickSearchOutside, false);
  // }

  // componentWillUnmount(){
  //   window.addEventListener('mousedown', this.handleClickSearchOutside, false);
  // }


  // handleClickSearchOutside = (e) => {
  //   if (this.node.contains(e.target)) {
  //       return;
  //   }
  //   this.clickSearchOutside();
  // }


  // clickSearchOutside(){
  //    if (this.state.isSearchOpen === true){
  //     this.setState({
  //       searchClass: 'hideSearchBar',
  //     }); 
  //   }
  // }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  searchToggle() {

    this.setState({
      isSearchOpen: !this.state.isSearchOpen,
      searchClass: 'showSearchBar',
      searchIcon: 'fa-solid fa-x',
    });

    if (this.state.isSearchOpen === true){
      this.setState({
        searchClass: 'hideSearchBar',
        searchIcon: 'fas fa-search',
      }); 
      // document.getElementsByTagName("html")[0].style.overflow='initial'
    }
    else {
      this.setState({
        searchClass: 'showSearchBar',
        searchIcon: 'fa-solid fa-x',
      }); 
      // document.getElementsByTagName("html")[0].style.overflow='hidden'
    }
  }

  quickSearch(selectedvalue){
    this.setState({
      quickSearchValue: selectedvalue
    });       
  }

   handleQuickSearchChange(event) {
    this.setState({
      quickSearchValue: event.target.value
    })
  }

  render(){
    return(
      <>
        {/* our business contact us section*/}
        <section className="business-navbar">
          <Container>
            <Navbar>
              <Nav className="ml-auto">
                <UncontrolledDropdown className="globalnav-dropdown">
                  <DropdownToggle caret tag="a" className="text-white">
                    Our Business
                  </DropdownToggle>
                  <DropdownMenu>
                    <NavItem>
                      <NavLink id="OurBusiness-Home" >
                        Home
                        <i className="fas fa-chevron-right position-absolute"></i>
                      </NavLink>
                    </NavItem>
                      <UncontrolledPopover trigger="legacy" placement="right" className="ourbusiness-popover" target="OurBusiness-Home">
                        <PopoverBody>
                          <strong>PLDT HOME</strong> believes that the strongest connections are at home. It provides Filipino families with a compelling suite of multimedia products and services so they can stay connected and easily access quality entertainment, information, security and other digital services in the comfort of their homes.
                          <br/><br/>
                          <Button href="https://pldthome.com/" target="_blank" rel="noopener noreferrer">GO TO WEBSITE</Button>
                          </PopoverBody>
                      </UncontrolledPopover>
                    <RenderBusinessDropdown menudropdown={OurBusinessMenu} />
                    <NavItem>
                      <NavLink id="OurBusiness-International">
                        International
                        <i className="fas fa-chevron-right position-absolute"></i>
                      </NavLink>
                    </NavItem>
                      <UncontrolledPopover trigger="legacy" placement="right" className="ourbusiness-popover" target="OurBusiness-International">
                        <PopoverBody>
                          <strong>PLDT Global</strong> delivers international services for overseas Filipinos as the leading offshore Filipino information and communications technology company.
                          <br/><br/>
                          <Button href="https://www.pldtglobal.com/" target="_blank" rel="noopener noreferrer">GO TO WEBSITE</Button>
                          </PopoverBody>
                      </UncontrolledPopover>
                    <NavItem>
                      <NavLink id="OurBusiness-Digital">
                        Digital
                        <i className="fas fa-chevron-right position-absolute"></i>
                      </NavLink>
                    </NavItem>
                      <UncontrolledPopover trigger="legacy" placement="right" className="ourbusiness-popover" target="OurBusiness-Digital">
                        <PopoverBody>
                          <strong>Maya Innovations Holdings Pte. Ltd. (VIH)</strong> is the parent holding company of Maya Philippines, Inc. (formerly PayMaya Philippines, Inc.) and Maya Bank.
                          <br/><br/>
                          <Button href="https://www.maya.ph/" target="_blank" rel="noopener noreferrer">GO TO WEBSITE</Button>
                          </PopoverBody>
                      </UncontrolledPopover>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="solo-nav">
                  <NavLink href="/contact-us" className="text-white">
                  Contact Us
                  </NavLink> 
                </NavItem>

                <NavItem className="solo-nav">
                  <NavLink onClick={this.searchToggle} className="text-white">
                  <i className={this.state.searchIcon}></i>
                  </NavLink> 
                </NavItem>
               
              </Nav>
            </Navbar>
          </Container>
        </section>
        {/* MENU NAV */}
        <DesktopNav/>
        <section  className={this.state.searchClass} >
          <Container fluid id="homeSearchBar" className="py-5 homepageSearchBar">
              <Row>
                <Col md={{size: 10, offset: 1}}>
                    <Form action="/search">
                      <InputGroup>
                          <Input
                            type="search"
                            name="search"
                            placeholder="What are you looking for?"
                            value={this.state.quickSearchValue}
                            onChange={this.handleQuickSearchChange}
                          />                          
                        <InputGroupAddon addonType="append"><i className="fas fa-search"></i></InputGroupAddon>
                      </InputGroup>
                    </Form>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{size: 10, offset: 1}}>
                  <div className="d-flex">
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('Top Stories about PLDT')}}>Top Stories about PLDT</Button>
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('PLDT Investor Relations')}}>PLDT Investor Relations</Button>
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('PLDT’s Business')}}>PLDT’s Business</Button>
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('Work Opportunities at PLDT')}}>Work Opportunities at PLDT</Button>
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('PLDT’s Journey of Innovation')}}>PLDT’s Journey of Innovation</Button>
                    <Button className="quicklinks-btn" onClick={() => {this.quickSearch('Annual Reports')}}>Annual Reports</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    )
  }
}


export default function Navigation() {
  return (
    <Fragment>
      {/* DESKTOP NAV */}
      <div className="d-none d-lg-block">
        <DekstopGlobalNav/>
      </div>
      {/* MOBILE NAV */}
      <div className="d-block d-lg-none">
        <MobileNav/>
      </div>
    </Fragment>
  );
}